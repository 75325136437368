import React from 'react'
import { QueryClientProvider } from 'react-query'

import client from './query-client'

interface Props {
    children?: React.ReactNode
}

export const Provider: React.FC<Props> = ({ children }) =>
    <QueryClientProvider client={client}>{children}</QueryClientProvider>
