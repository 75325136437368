import React, { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@emotion/react'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import './helper/dayjs'
import { AppWrapper } from './app.style'
import { Provider as QueryProvider } from './api'
import { AuthProvider, SchemaRoutesProvider } from './providers'
import { Router } from './router'
import IntlProvider from './providers/intl-provider'
import { AbilityProvider } from './providers/ability'
import { Config } from './config'
import { Eneriq } from './themes/eneriq'
import { DateRangeProvider } from './providers/date-range'
import AntdConfigProvider from './providers/antd-config-provider'
import MqttRegistryProvider from './providers/mqtt/mqtt-registry-provider'

const App: React.FC = () => {
    const [locale] = useState<string>(navigator.language)

    return (
        <ThemeProvider theme={Eneriq}>
            <AppWrapper className="App">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{Config.site.title}</title>
                </Helmet>

                <IntlProvider locale={locale}>
                    <SchemaRoutesProvider>
                        <BrowserRouter>
                            <QueryProvider>
                                <AuthProvider>
                                    <AbilityProvider>
                                        <AntdConfigProvider>
                                            <QueryParamProvider adapter={ReactRouter6Adapter}>
                                                <MqttRegistryProvider>
                                                    <DateRangeProvider>
                                                        <Router />

                                                        <ReactQueryDevtools initialIsOpen={false} />
                                                    </DateRangeProvider>
                                                </MqttRegistryProvider>
                                            </QueryParamProvider>
                                        </AntdConfigProvider>
                                    </AbilityProvider>
                                </AuthProvider>
                            </QueryProvider>
                        </BrowserRouter>
                    </SchemaRoutesProvider>
                </IntlProvider>
            </AppWrapper>
        </ThemeProvider>
    )
}

export default App
