const loader = {
    en: import('./en.json'),
    de: import('./de.json')
}

export const getTranslationMessages = async (locale: string): Promise<Record<string, string>> => {
    let [lang] = locale.split(/[-_]/)

    if (!(lang in loader)) {
        lang = 'en'
    }

    const messages = await loader[lang as keyof typeof loader]

    if (messages.default) {
        return messages.default
    }

    throw new Error('Error loading translations')
}
