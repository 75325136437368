import React, { PropsWithChildren } from 'react'
import { ConfigProvider } from 'antd'
import { useIntl } from 'react-intl'

import messages from './ant-config-provider.intl'

const AntdConfigProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const intl = useIntl()
    const formValidateMessages = {
        required: intl.formatMessage(messages.validateMessagesRequired)
    }

    return (
        <ConfigProvider form={{ validateMessages: formValidateMessages }}>
            {children}
        </ConfigProvider>
    )
}

export default AntdConfigProvider
