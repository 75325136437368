// import { ReportHandler } from 'web-vitals'
import { getCLS, getFID, getLCP, getFCP, getTTFB } from 'web-vitals'

type Callback = (metric: unknown) => void

const reportWebVitals = (onPerfEntry?: Callback): void => {
    if (onPerfEntry) {
        getCLS(onPerfEntry)
        getFID(onPerfEntry)
        getFCP(onPerfEntry)
        getLCP(onPerfEntry)
        getTTFB(onPerfEntry)
    }
}

export default reportWebVitals
