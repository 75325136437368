export const toQuotedStrings = (preload: string | string[]): string => {
    if (Array.isArray(preload)) {
        return preload.map(toQuotedStrings).join(', ')
    }

    let quoted = preload

    // ensure the string is double-quoted
    if (!quoted.startsWith('"')) {
        quoted = `"${quoted}`
    }
    if (!quoted.endsWith('"')) {
        quoted = `${quoted}"`
    }

    return quoted
}
