import styled from '@emotion/styled'

import 'nprogress/nprogress.css'
import './app.css'

export const AppWrapper = styled.div`
    min-height: 100vh;
  
    .app-layout {
        &.no-sider {
            .ant-layout-header {
                padding-left: 0;
            }
        }
    }

    .ant-layout-sider {
        color: ${props => props.theme.color.sidebarText};

        &.ant-layout-sider-dark {
            background: ${props => props.theme.color.sidebarBackground};

            .ant-menu-item-divider {
                border-color: ${props => props.theme.color.siderSeparator};
            }
        }

        .ant-menu {
            margin-top: 20px;

            &.ant-menu-dark {
                background: ${props => props.theme.color.sidebarBackground};

                .ant-menu-item-selected {
                    background: ${props => props.theme.color.menuItemSelectedBackground};
                }
            }
          
            &.ant-menu-vertical {
                .ant-menu-item {
                    margin-top: 0;
                    margin-bottom: 0;
                    height: 50px;
                    line-height: 50px;
                }
            }
        }
    }

    .ant-table {
        .ant-table-summary td {
            background-color: #fafafa;
        }
    }

    .ant-descriptions {
        &.single-col {
            .ant-descriptions-item-label {
                width: 25%;
                vertical-align: top;
            }
        }
    }

    .ant-card {
        .ant-page-header-heading-title {
            font-size: .9rem;
        }

        &.multi-stat-card {
            .ant-row {
                margin-bottom: 0;
            }

            .ant-col {
                margin-bottom: 0;
            }
        }
    }

    address {
        font-style: italic;
        &:last-child {
          margin-bottom: 0;
        }
    }
`
