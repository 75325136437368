import { defineMessages } from 'react-intl'

export default defineMessages({
    '4L': {
        id: 'unit_codes.4L',
        defaultMessage: 'mb'
    },
    'AMP': {
        id: 'unit_codes.AMP',
        defaultMessage: 'A'
    },
    'BAR': {
        id: 'unit_codes.BAR',
        defaultMessage: 'bar'
    },
    'BIN': {
        id: 'unit_codes.BIN',
        defaultMessage: 'bin'
    },
    'C26': {
        id: 'unit_codes.C26',
        defaultMessage: 'ms'
    },
    'CEL': {
        id: 'unit_codes.CEL',
        defaultMessage: '°C'
    },
    'CMQ': {
        id: 'unit_codes.CMQ',
        defaultMessage: 'cm³'
    },
    'E32': {
        id: 'unit_codes.E32',
        defaultMessage: 'l/h'
    },
    'HTZ': {
        id: 'unit_codes.HTZ',
        defaultMessage: 'Hz'
    },
    'KGM': {
        id: 'unit_codes.KGM',
        defaultMessage: 'kg'
    },
    'KWH': {
        id: 'unit_codes.KWH',
        defaultMessage: 'kWh'
    },
    'KWT': {
        id: 'unit_codes.KWT',
        defaultMessage: 'kW'
    },
    'L': {
        id: 'unit_codes.L',
        defaultMessage: 'L'
    },
    'MQH': {
        id: 'unit_codes.MQH',
        defaultMessage: 'm³/h'
    },
    'MTK': {
        id: 'unit_codes.MTK',
        defaultMessage: 'm²'
    },
    'MTQ': {
        id: 'unit_codes.MTQ',
        defaultMessage: 'm³'
    },
    'MWH': {
        id: 'unit_codes.MWH',
        defaultMessage: 'MWh'
    },
    'NIL': {
        id: 'unit_codes.NIL',
        defaultMessage: 'n/d'
    },
    'P1': {
        id: 'unit_codes.P1',
        defaultMessage: '%'
    },
    'SCO': {
        id: 'unit_codes.SCO',
        defaultMessage: 'Score'
    },
    'SEC': {
        id: 'unit_codes.SEC',
        defaultMessage: 's'
    },
    'TMP': {
        id: 'unit_codes.TMP',
        defaultMessage: 't.b.d.'
    },
    'V': {
        id: 'unit_codes.V',
        defaultMessage: 'V'
    },
    'VAR': {
        id: 'unit_codes.VAR',
        defaultMessage: '~'
    },
    'WTT': {
        id: 'unit_codes.WTT',
        defaultMessage: 'W'
    }
})
