import { Iri } from '../../contract'
import { getClient } from '../http-client'
import { produceDefaultHeaders } from '../helper/produce-default-headers'

export type DeleteIriOptions = Record<string, unknown>

export const deleteIri = async (
    iri: Iri,
    options?: DeleteIriOptions
): Promise<boolean> => {
    try {
        await getClient().delete(iri, {
            headers: {
                ...produceDefaultHeaders(options)
            }
        })

        return true
    }
    catch (error) {
        return false
    }
}
