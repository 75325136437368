import { useState } from 'react'

export const useLocalStorage = <S>(key: string, initialState?: S): [S, (value: S) => void] => {
    let storageItem

    if (key) {
        const val = localStorage.getItem(key) || null

        try {
            storageItem = val ? JSON.parse(val) : val
        }
        catch (_) {
            storageItem = val
        }

        if (!storageItem) {
            storageItem = initialState
        }
    }

    const [localState, updateLocalState] = useState<S>(storageItem)

    const setItem = (value: S) => {
        if (value === undefined) {
            localStorage.setItem(key, '')
            localStorage.removeItem(key)
        }
        else if (typeof value === 'string') {
            localStorage.setItem(key, value)
        }
        else {
            localStorage.setItem(key, JSON.stringify(value))
        }

        updateLocalState(value)
    }

    return [localState, setItem]
}
