import get from 'lodash/get'

import { getIdentifierFromRelation } from 'helper'
import { SchemaRouteResolver } from '../../helper/route-resolver/schema-route-resolver'

import { TechnicalInstallation } from './model'

export default {
    item: {
        supports: (data: unknown): data is TechnicalInstallation => get(data, '@type') === 'TechnicalInstallation',
        resolve: (data: unknown): Promise<string | undefined> => {
            const casted = data as TechnicalInstallation

            if (casted.economicUnit) {
                const ecouId = getIdentifierFromRelation(casted.economicUnit)?.memberId

                return Promise.resolve(`/dashboard/economic_units/${ecouId}/settings/installations/${casted.id}`)
            }

            return Promise.resolve(undefined)
        }
    }
} as SchemaRouteResolver
