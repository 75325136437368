import set from 'lodash/set'
import type { InternalAxiosRequestConfig } from 'axios'

export default (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const jwt = localStorage.getItem('jwt')

    if (jwt) {
        set(config, 'headers.authorization', `Bearer ${jwt}`)
    }

    return config
}
