import axios, { AxiosResponse } from 'axios'

import { PersistentJsonLdEntity } from '../../contract'
import { getClient } from '../http-client'
import { ConstraintViolationList } from '../../schema'
import { ValidationError } from '../validation/validation-error'
import { normalizeApiResourceName } from '../helper/normalize-api-resource-name'
import { produceDefaultHeaders } from '../helper/produce-default-headers'

import { PreloadAware } from './preload-aware'

export type CreateItemOptions = PreloadAware

export const createItem = async <Model extends object>(
    resourceName: string,
    payload: Partial<Model>,
    options?: CreateItemOptions
): Promise<Model & PersistentJsonLdEntity> => {
    try {
        const res = await getClient().post<
            Model & PersistentJsonLdEntity,
            AxiosResponse<Model & PersistentJsonLdEntity>,
            Partial<Model>
        >(
            `/api/${normalizeApiResourceName(resourceName)}`,
            payload,
            {
                headers: {
                    ...produceDefaultHeaders(options)
                }
            })

        return res.data
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 422 && error.response?.data) {
                const data = error.response.data as ConstraintViolationList
                const description = 'hydra:description' in data ? data['hydra:description'] : undefined

                throw new ValidationError(`${description}`, data.violations)
            }
        }

        throw error
    }
}
