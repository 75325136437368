import { AnyAbility, defineAbility } from '@casl/ability'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import get from 'lodash/get'
import { camelize, pascalize } from 'humps'
import { plural, singular } from 'pluralize'

import { AuthToken } from '../../components/auth/model'
import { User } from '../../components/users/model'
import { Organization } from '../../components/organizations/model'
import { Config } from '../../config'

import { APP_DASHBOARD, ECOU_SETTINGS } from './special-subjects'

const READER_ABILITY = ['read', 'view']
const WRITER_ABILITY = ['write', 'create', 'update']
const MANAGER_ABILITY = ['delete', 'remove', 'manage']

const apiPermissionToActions = (permission: string): string[] => {
    if (permission === 'READER') {
        return READER_ABILITY
    }

    if (permission === 'WRITER') {
        return READER_ABILITY
            .concat(WRITER_ABILITY)
    }

    if (permission === 'MANAGER') {
        return READER_ABILITY
            .concat(WRITER_ABILITY)
            .concat(MANAGER_ABILITY)
    }

    return []
}

const getResourceNameVariations = (resourceName: string): string[] => {
    const singularCase = singular(resourceName)
    const pluralCase = plural(singularCase)

    const res = [
        singularCase, singularCase.toLowerCase(), singularCase.toUpperCase(),
        pluralCase, pluralCase.toLowerCase(), pluralCase.toUpperCase()
    ]

    res.push(camelize(singularCase.toLowerCase()))
    res.push(camelize(pluralCase.toLowerCase()))
    res.push(pascalize(singularCase.toLowerCase()))
    res.push(pascalize(pluralCase.toLowerCase()))

    return uniq(res)
}

export default (
    token: AuthToken,
    user?: User,
    currentOrganization?: Organization
    // eslint-disable-next-line complexity
): AnyAbility => defineAbility<AnyAbility>((can, cannot) => {
    const roles = token.roles?.concat(user?.roles || [])
    const isMultiClient = currentOrganization && get(currentOrganization, 'config.multi_client') === true

    if (!roles || isEmpty(roles)) {
        return
    }

    if (roles.includes('ROLE_SUPER_ADMIN')) {
        can('manage', 'all')
    }
    else if (roles) {
        can('see', APP_DASHBOARD) // special flag to allow access to frontpage/dashboard

        if (roles.includes('ROLE_MANAGER')) {
            can('see', ECOU_SETTINGS)
        }

        for (const role of roles) {
            const matched = role.match(/^ROLE_(?<resource>[A-Z_]+)_(?<permission>[A-Z]+)$/)

            // define an artificial "assume" ability for each role name
            can('assume', role)

            if (matched?.groups?.resource && matched?.groups?.permission) {
                const resourceNameVariations = getResourceNameVariations(matched.groups.resource)
                const actions = apiPermissionToActions(matched.groups.permission)
                const fields: string[] = ['*']

                can(actions, resourceNameVariations, fields)

                if (!isMultiClient) {
                    cannot(actions, resourceNameVariations, ['owner'])
                }
            }
        }

        if (!isMultiClient) {
            cannot('create', getResourceNameVariations('organizations'))
        }

        if (!roles.includes('ROLE_ADMIN')) {
            cannot('delete', getResourceNameVariations('command_messages'))
        }

        if (roles.includes('ROLE_EIQ_READER') || roles.includes('ROLE_EXTENDED_TIMESERIES_READER')) {
            cannot('read', getResourceNameVariations('ExtendedTimeseries'))
        }

        if (roles.includes('ROLE_EIQ_ADMIN')) {
            can('control', 'DataProvider')
            can('create', getResourceNameVariations('command_messages'), { type: 'EconomicUnits/transferOwnership' })
        }
        else {
            cannot('create', getResourceNameVariations('command_messages'), { type: 'EconomicUnits/transferOwnership' })
        }
    }

    if (isMultiClient) {
        can('manage', 'Clients')
        can('manage', 'clients')
        can('manage', 'multiClients')
    }

    if (roles.includes('ROLE_DASHBOARDING_ONLY') || roles.includes('ROLE_DASHBOARDING_ONLY_READER')) {
        cannot('see', APP_DASHBOARD) // special flag to force redirect away from app dashboard

        cannot('create', 'all')
        cannot('update', 'all')
        cannot('delete', 'all')
        cannot('write', 'all')

        cannot('read', getResourceNameVariations('api_keys')) // to hide the API keys section in settings
        cannot('read', getResourceNameVariations('data_providers')) // to hide the dashboard stats
        cannot('read', getResourceNameVariations('digital_documents')) // to hide the documents tab on ecou page
        cannot('read', getResourceNameVariations('monitors'))
        cannot('read', getResourceNameVariations('measurements')) // to hide monitoring tab on ecou page
        cannot('read', getResourceNameVariations('notification_channel')) // to hide the settings item
        cannot('read', getResourceNameVariations('notification_subscription'))

        can('create', getResourceNameVariations('comments'))

        if (user) {
            can('delete', getResourceNameVariations('comments'), { createdBy: user['@id'] })
        }
    }

    const defineFeature = (flagId: string, accessible: boolean) => {
        const fn = accessible ? can : cannot

        fn('see', flagId)

        for (const a of READER_ABILITY.concat(WRITER_ABILITY).concat(MANAGER_ABILITY)) {
            fn(a, flagId)
        }
    }

    for (const flagId of Object.keys(Config.featureFlags)) {
        const value = Config.featureFlags[flagId as keyof typeof Config.featureFlags]

        if (typeof value === 'boolean') {
            defineFeature(flagId, value)
        }

        // when given a string we conside this a RoleName which is required to turn the feature active
        if (typeof value === 'string') {
            defineFeature(flagId, roles.includes(value))
        }
    }
})
