import React, { useCallback, useMemo, useState } from 'react'
import dayjs, { ManipulateType } from 'dayjs'
import { useQueryParam, StringParam, withDefault } from 'use-query-params'
import isEqual from 'lodash/isEqual'

import { AcceptedDateInput, AcceptedRangeInput, DateRange, RangeText, ZoomRange } from './types'
import DateRangeContext from './date-range-context'
import { dateFromZoomInput, normalizeDateRange, serializeDateRange } from './helpers'

interface Props {
    initialRange?: AcceptedRangeInput
    children: React.ReactNode
}

const DefaultFromParam = withDefault(StringParam, dayjs().subtract(7, 'days').format())

export const DateRangeProvider: React.FC<Props> = ({ children }) => {
    const [zoomFrom, setZoomFrom] = useQueryParam('from', DefaultFromParam)
    const [zoomTo, setZoomTo] = useQueryParam('to', StringParam)
    const [dateRange, setDateRange] = useState<DateRange>({
        from: dayjs().subtract(3, 'months').startOf('day'),
        to: undefined
    })

    const zoomRange = useMemo<ZoomRange>(() => ({
        from: zoomFrom ? dateFromZoomInput(zoomFrom) : dayjs().subtract(7, 'days'),
        to: zoomTo ? dayjs(zoomTo) : undefined
    }), [zoomFrom, zoomTo])

    const setFromDate = useCallback((input: AcceptedDateInput) => {
        setDateRange({
            ...dateRange,
            from: dayjs(input)
        })
    }, [dateRange])
    const setToDate = useCallback((input: AcceptedDateInput) => {
        setDateRange({
            ...dateRange,
            to: dayjs(input)
        })
    }, [dateRange])

    const setRangeFromInput = useCallback((input: AcceptedRangeInput | DateRange) => {
        setDateRange(normalizeDateRange(input))
    }, [])

    const setZoomFromDate = useCallback((input: AcceptedDateInput) => {
        setZoomFrom(dayjs(input).format())
    }, [setZoomFrom])
    const setZoomToDate = useCallback((input: AcceptedDateInput) => {
        setZoomTo(dayjs(input).format())
    }, [setZoomTo])

    const setZoomRangeFromInput = useCallback((input: AcceptedRangeInput | DateRange | RangeText) => {
        let newRange: DateRange | undefined

        if (typeof input === 'string') {
            const matches = input.match(/(?<count>\d+)(?<unit>\w{1})/)

            if (matches) {
                // console.log('in data range prov', matches)
                const count = Number(matches[1])
                let unit: ManipulateType

                switch (matches[2] as string) {
                    case 'h':
                        unit = 'hours'
                        break
                    case 'd':
                        unit = 'days'
                        break
                    case 'w':
                        unit = 'weeks'
                        break
                    case 'm':
                        unit = 'months'
                        break
                    case 'y':
                        unit = 'years'
                        break
                    default:
                        unit = 'days'
                        break
                }

                newRange = {
                    from: dayjs().subtract(count, unit),
                    to: dayjs()
                }

                if (newRange && !isEqual(serializeDateRange(newRange), serializeDateRange(zoomRange))) {
                    setZoomFromDate(newRange.from)
                    setZoomToDate(newRange.to)
                    // setZoomRange(newRange)
                }
            }
        }
        else {
            newRange = normalizeDateRange(input)

            if (newRange && !isEqual(serializeDateRange(newRange), serializeDateRange(zoomRange))) {
                setZoomFromDate(newRange.from)
                setZoomToDate(newRange.to)
            }
        }
    }, [zoomRange, setZoomFromDate, setZoomToDate])

    return (
        <DateRangeContext.Provider
            value={{
                dateRange,
                setDateRange: setRangeFromInput,
                setFromDate,
                setToDate,

                zoomRange,
                setZoomRange: setZoomRangeFromInput,
                setZoomFromDate,
                setZoomToDate
            }}
        >
            {children}
        </DateRangeContext.Provider>
    )
}

export default DateRangeProvider
