import has from 'lodash/has'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { InvalidateQueryFilters, QueryKey } from 'react-query'

import { QueryClient } from '../query-client'
import { guessResourceNameFromIri } from '../../helper'

import { HydraCollection, JsonLdEntity } from './client'

export const invalidateQueries = (queryKey: QueryKey, filters?: InvalidateQueryFilters): Promise<void> =>
    QueryClient.invalidateQueries(queryKey, filters)

export const addCollectionToCache = (_resourceName: string, collection: HydraCollection): Promise<void> => {
    if (has(collection, 'hydra:member')) {
        for (const member of get(collection, 'hydra:member')) {
            if (typeof member === 'object') {
                if (member['@id']) {
                    addMemberToCache(member)
                }
                if (has(member, 'id')) {
                    addMemberToCache(member)
                }
            }
        }
    }

    return Promise.resolve()
}

export const addMemberToCache = async (member: JsonLdEntity): Promise<void> => {
    if (member['@id']) {
        await cacheIfChanged(member['@id'], member)

        // also propagate a version which does not have any preload hints
        await cacheIfChanged([member['@id'], null], member)

        if (has(member, 'id')) {
            const resourceName = guessResourceNameFromIri(member['@id'])

            if (resourceName) {
                await cacheIfChanged([resourceName, `${get(member, 'id')}`], member)

                // also propagate a version which does not have any preload hints
                await cacheIfChanged([resourceName, `${get(member, 'id')}`, null], member)
            }
        }
    }

    return Promise.resolve()
}

export const removeMemberFromCache = (resourceName: string, id: string): Promise<void> => {
    const keys: QueryKey[] = [
        [resourceName, id],
        `/api/${resourceName}/${id}`
    ]

    QueryClient.removeQueries(keys)

    return Promise.resolve()
}

// this function provides a crucial guard: it only updates the
// cache if the cached value and the new value differ...
// this is important because we want to be able to call this helper
// from within Query.onSuccess callbacks... if we'd just go and update
// without this check we might end up in render loops
// @see https://github.com/tannerlinsley/react-query/discussions/1411#discussioncomment-1353361
export const cacheIfChanged = (key: QueryKey, value: unknown): Promise<void> => {
    const currentCache = QueryClient.getQueryData(key)

    if (currentCache) {
        if (!value) {
            QueryClient.removeQueries(key)
        }
        else if (!isEqual(currentCache, value)) {
            // console.log('call setQueryData (update)', key, value)
            QueryClient.setQueryData(key, value)
        }
    }
    else {
        // console.log('call setQueryData (add)', key, value)
        QueryClient.setQueryData(key, value)
    }

    return Promise.resolve()
}
