import { AxiosRequestConfig } from 'axios'

import { JsonLdEntity, Iri } from '../../contract'
import { getClient } from '../http-client'
import { produceDefaultHeaders } from '../helper/produce-default-headers'

import { PreloadAware } from './preload-aware'

export interface GetIriOptions extends PreloadAware {
    params?: AxiosRequestConfig['params']
}

export const getIri = async <Model extends object>(
    iri: Iri,
    options?: GetIriOptions
): Promise<Model & JsonLdEntity> => {
    if (typeof iri !== 'string') {
        throw new Error(`Expected iri argument to be a string, ${typeof iri} given`)
    }

    const res = await getClient().get<Model & JsonLdEntity>(iri, {
        params: options?.params,
        headers: {
            ...produceDefaultHeaders(options)
        }
    })

    return res.data
}
