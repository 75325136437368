import { AxiosResponse } from 'axios'

import { getClient } from '../http-client'
import { produceDefaultHeaders } from '../helper/produce-default-headers'
import { components } from '../../schema'
import { Credential } from '../auth/credentials'
import { AuthError } from '../../error/auth-error'

import type { AuthToken } from './auth-token'

let lastAuthTokenResponse: AuthToken | undefined

export const getLastAuthTokenResponse = (): AuthToken | undefined => lastAuthTokenResponse

export const createAuthToken = async (
    credential: Credential
): Promise<components['schemas']['AuthToken.jsonld-read']> => {
    let res: AxiosResponse<components['schemas']['AuthToken.jsonld-read']> | undefined

    if (credential.keypair) {
        res = await getClient().post<components['schemas']['AuthToken.jsonld-read']>('/api/auth_token', {}, {
            headers: {
                ...produceDefaultHeaders(),
                'x-api-key': credential.keypair.apiKey,
                'x-api-secret': credential.keypair.apiSecret
            }
        })
    }
    else if (credential.user) {
        res = await getClient().post<components['schemas']['AuthToken.jsonld-read']>('/api/auth_token', credential.user, {
            headers: {
                ...produceDefaultHeaders()
            }
        })
    }

    if (!res?.data) {
        throw new AuthError('Could not authenticate')
    }

    lastAuthTokenResponse = res.data

    return res.data
}
