import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'

import { getClient } from '../http-client'
import { AuthError } from '../../error/auth-error'

import type { AuthToken, OAuthTokenResponse } from './auth-token'

export const refreshAuthToken = async (
    authToken: AuthToken
): Promise<AuthToken> => {
    let res: AxiosResponse<OAuthTokenResponse> | undefined

    if (authToken.refreshToken) {
        res = await getClient().post<OAuthTokenResponse>('/oauth2/token', {
            /* eslint-disable @typescript-eslint/naming-convention, camelcase */
            client_id: 'web',
            grant_type: 'refresh_token',
            refresh_token: authToken.refreshToken
            /* eslint-enable */
        }, {
            headers: {
                'content-type': 'application/json'
            }
        })
    }

    if (!res?.data) {
        throw new AuthError('Could not authenticate')
    }

    return {
        ...authToken,
        expiresAt: dayjs().add(res.data.expires_in, 'seconds').toISOString(),
        token: res.data.access_token,
        refreshToken: res.data.refresh_token
    }
}
