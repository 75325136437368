import { QueryClient as BaseQueryClient } from 'react-query'

export const QueryClient = new BaseQueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: (_failureCount, error) => {
                if (error instanceof Error && error.toString().includes('404')) {
                    return false
                }

                return false
            }
        }
    }
})

export default QueryClient
