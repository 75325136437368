import React, { Suspense, lazy } from 'react'
import { Routes, Route, Outlet, RouteProps } from 'react-router-dom'

import { useAuth } from './hooks'
import { LazyProgress } from './components/lazy-progress'

const Forbidden = lazy(() => import('pages/forbidden'))
const Error = lazy(() => import('pages/error'))
const SignIn = lazy(() => import('pages/sign-in'))
const SignUp = lazy(() => import('pages/sign-up'))
const SignOut = lazy(() => import('pages/sign-out'))
const RequestPasswordReset = lazy(() => import('pages/password-reset'))
const Confirm = lazy(() => import('pages/confirm'))
const NotFound = lazy(() => import('pages/not-found'))
const Testing = lazy(() => import('pages/testing'))
const Main = lazy(() => import('pages/main/main'))
const WidgetStandalone = lazy(() => import('pages/widget-standalone'))
const DashboardStandalone = lazy(() => import('pages/main/dataviz/dashboard'))
const PrivateRoute: React.FC<RouteProps> = () => {
    const { isAuthenticated } = useAuth()

    return isAuthenticated ? <Outlet /> : <Forbidden redirectToLogin />
}

export const Router: React.FC = () =>
    <Suspense fallback={<LazyProgress />}>
        <Routes>
            <Route path="/" element={<PrivateRoute />}>
                <Route path="/error" element={<Error />} />
                <Route path="/testing" element={<Testing />} />

                <Route path="/dashboard/widgets/:id" element={<WidgetStandalone />} />
                <Route path="/dashboard/*" element={<Main />} />
                <Route path="/dashboards/:id" element={<DashboardStandalone />} />

                <Route path="*" element={<NotFound />} />
            </Route>

            <Route index element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signup/:invitationId" element={<SignUp />} />
            <Route path="/confirm/:token" element={<Confirm />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signout" element={<SignOut />} />
            <Route path="/reset_password" element={<RequestPasswordReset />} />
            <Route path="/reset_password/:resetToken" element={<RequestPasswordReset />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </Suspense>
