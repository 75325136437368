import dayjs from 'dayjs'

export type AcceptedDateInput = string | dayjs.Dayjs | undefined;

export interface AcceptedRangeInput {
    from: AcceptedDateInput
    to?: AcceptedDateInput
}

export interface SerializedDateRange {
    from?: string
    to?: string
}

export type RangeText = string

export interface DateRange {
    from?: dayjs.Dayjs
    to?: dayjs.Dayjs
}

export interface ZoomRange extends DateRange {
    isAbsoluteMax?: boolean
}

export interface RelativeDateRange extends DateRange {
    key: RelativeDateRanges
}

export enum RelativeDateRangeKey {
    HOUR_1 = '1 hour',
    HOUR_6 = '6 hours',
    HOUR_12 = '12 hours',
    DAY_1 = '1 day',
    DAY_2 = '2 days',
    WEEK_1 = '1 week',
    MONTH_1 = '1 month',
    MONTH_3 = '3 months',
    MONTH_6 = '6 months',
    MTD = 'month to date',
    YTD = 'year to date',
}
type RelativeDateRanges = keyof typeof RelativeDateRangeKey
