import { AxiosRequestHeaders } from 'axios'

import { PreloadAware } from '../endpoint/preload-aware'

import { normalizePreload } from './normalize-preload'
import { normalizeFields } from './normalize-fields'

type AcceptedOptions = undefined | Record<string, unknown> | PreloadAware

export const produceDefaultHeaders = (options?: AcceptedOptions): AxiosRequestHeaders => {
    const headers: AxiosRequestHeaders = {
        'Content-Type': 'application/ld+json'
    }

    if (options?.preload && Array.isArray(options.preload)) {
        headers.preload = normalizePreload(options.preload)
    }
    if (options?.fields && Array.isArray(options.fields)) {
        headers.fields = normalizeFields(options.fields)
    }

    return headers
}
