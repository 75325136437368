import { JsonLdEntity } from '../../contract'
import { isIri } from '../validation/is-iri'

import { getCollection } from './get-collection'
import { PreloadAware } from './preload-aware'
import { getIri } from './get-iri'

export type FindItemOptions = PreloadAware

export const findItem = async <Model extends object>(
    resourceName: string,
    query: Record<string, unknown>,
    options?: FindItemOptions
): Promise<Model & JsonLdEntity | undefined> => {
    const collection = await getCollection<Model & JsonLdEntity>(resourceName, {
        ...options,
        itemsPerPage: 1,
        filters: query
    })

    if (collection?.['hydra:member']) {
        const [item] = collection['hydra:member']

        // we received an IRI here - proceed to fetch the item
        if (isIri(item)) {
            return getIri<Model & JsonLdEntity>(item, options)
        }

        return item
    }

    return undefined
}
