import type { InternalAxiosRequestConfig } from 'axios'
import { stringify } from 'qs'

export default (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    config.paramsSerializer = {
        serialize: params =>
            stringify(params, {
                arrayFormat: 'brackets',
                encode: true
            })
    }

    return config
}
