import { createContext } from 'react'
import { Client } from 'precompiled-mqtt'

export interface IMqttRegistryContext {
    clients: Client[]
    getClient: (id: string) => Client | undefined
    setClient: (id: string, client: Client | undefined) => void
}

export const MqttRegistryContext = createContext<IMqttRegistryContext>({
    clients: [],
    getClient: () => undefined,
    setClient: () => undefined
})

export default MqttRegistryContext
