import dayjs, { Ls, extend } from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import RelativeTime from 'dayjs/plugin/relativeTime'
import UpdateLocale from 'dayjs/plugin/updateLocale'
import LocaleData from 'dayjs/plugin/localeData'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import CustomParseFormat from 'dayjs/plugin/customParseFormat'
import Duration from 'dayjs/plugin/duration'
import Weekday from 'dayjs/plugin/weekday'
import WeekOfYear from 'dayjs/plugin/weekOfYear'
import WeekYear from 'dayjs/plugin/weekYear'

extend(LocalizedFormat)
extend(RelativeTime)
extend(Duration)
extend(LocaleData)
extend(AdvancedFormat)
extend(CustomParseFormat)
extend(Weekday)
extend(WeekOfYear)
extend(WeekYear)
extend(UpdateLocale)

dayjs.updateLocale('de', {
    relativeTime: {
        ...Ls.de?.relativeTime,
        dd: '%d Tagen'
    }
})

export default dayjs
