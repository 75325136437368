import { Violation } from '../../contract/violation'

export class ValidationError extends Error {
    public readonly violations: Violation[]

    constructor (message: string, violations: Violation[] = []) {
        super(message)

        this.violations = violations
    }
}
