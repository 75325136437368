import React, { useState } from 'react'
import { Client } from 'precompiled-mqtt'

import MqttRegistryContext from './mqtt-registry-context'

interface Props {
    children: React.ReactElement
}

const MqttRegistryProvider: React.FC<Props> = ({ children }) => {
    const [registry, setRegistry] = useState<Record<string, Client | undefined>>({})

    return (
        <MqttRegistryContext.Provider
            value={{
                setClient: (id, client) => {
                    setRegistry({
                        ...registry,
                        [id]: client
                    })
                },
                getClient: id => registry[id],
                clients: Object.values(registry).filter((n): n is Client => Boolean(n))
            }}
        >
            {children}
        </MqttRegistryContext.Provider>
    )
}

export default MqttRegistryProvider
