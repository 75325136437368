import { stringify } from 'qs'

import { getClient } from '../http-client'

export const createUrl = (path: string, queryParams?: Record<string, unknown>): URL => {
    const client = getClient()
    const baseUrl = client.defaults.baseURL
    const url = new URL(`${baseUrl}${path}`)

    if (queryParams) {
        url.search = stringify(queryParams)
    }

    return url
}
