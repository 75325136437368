// import { AxiosError } from 'axios'

import axios from 'axios'

import { Iri } from '../../contract'
import { getClient } from '../http-client'
import { ConstraintViolationList } from '../../schema/constraint-violation-list'
import { ValidationError } from '../validation/validation-error'
import { produceDefaultHeaders } from '../helper/produce-default-headers'

import { PreloadAware } from './preload-aware'

export type PostIriOptions = PreloadAware

export const postIri = async <Response extends object, Request extends object>(
    iri: Iri,
    payload: Request,
    options?: PostIriOptions
): Promise<Response> => {
    try {
        const res = await getClient().post<Response>(iri, payload, {
            headers: {
                ...produceDefaultHeaders(options)
            }
        })

        return res.data
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 422 && error.response?.data) {
                const data = error.response.data as ConstraintViolationList
                const description = 'hydra:description' in data ? data['hydra:description'] : undefined

                throw new ValidationError(`${description}`, data.violations)
            }
        }

        throw error
    }
}
