import { AxiosInstance } from 'axios'

import { Config } from './config'
import { createClient } from './api/http-client'

let instance: AxiosInstance

export const initClient = (config: Config, forceNew?: boolean): AxiosInstance => {
    if (!instance || forceNew) {
        instance = createClient(config.host, config.httpClient)
    }

    return instance
}
