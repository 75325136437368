import React, { useCallback } from 'react'

import { SchemaRouteResolver } from '../../helper/route-resolver/schema-route-resolver'

import SchemaRoutesContext from './schema-routes-context'

interface IProps {
  children: React.ReactElement
}

const resolverRegistry: Record<string, SchemaRouteResolver> = {}

export const SchemaRoutesProvider: React.FC<IProps> = ({ children }) => {
    const addResolver = useCallback((resourceName: string, resolver: SchemaRouteResolver) => {
        resolverRegistry[resourceName] = resolver
    }, [])

    return (
        <SchemaRoutesContext.Provider
            value={{
                addResolver,
                getCollectionRoute: (resourceName, context) => {
                    const resolver = resolverRegistry[resourceName]?.collection

                    if (resolver?.supports(resourceName, context)) {
                        return resolver.resolve(resourceName, context)
                    }

                    return Promise.resolve(undefined)
                },
                getItemRoute: (data, context) => {
                    console.log('get item route', data, context)
                    // const resolver = resolverRegistry[resourceName]?.collection
                    //
                    // if (resolver?.supports(resourceName, context)) {
                    //     return resolver.resolve(resourceName, context)
                    // }

                    return Promise.resolve(undefined)
                }
            }}
        >
            {children}
        </SchemaRoutesContext.Provider>
    )
}

export default SchemaRoutesProvider
