import React, { useEffect } from 'react'
import nProgress from 'nprogress'
import { Skeleton } from 'antd'

interface LazyProgressProps {
    withSkeleton?: boolean
}

export const LazyProgress: React.FC<LazyProgressProps> = ({ withSkeleton }) => {
    useEffect(() => {
        if (!nProgress.isStarted()) {
            nProgress.start()
        }

        return () => {
            nProgress.done()
        }
    })

    return withSkeleton
        ? <Skeleton active avatar paragraph round title />
        : <></>
}
