import isEmpty from 'lodash/isEmpty'

/* eslint-disable @typescript-eslint/naming-convention,camelcase */
import TechnicalInstallationsResolver from 'components/technical-installations/schema-route-resolver'

import { SchemaRouteResolver } from './helper/route-resolver/schema-route-resolver'
import { ECOU_JOURNAL, ECOU_MEASURES } from './providers/ability/special-subjects'
import type { RoleName } from './providers/ability/types'

interface ConfigInterface {
    site: {
        title: string
        version?: string
        footerText?: string
    }
    essApi: {
        host?: string
    }
    schemaRouteResolvers: Record<string, SchemaRouteResolver>
    sentry: {
        dsn?: string
        enabled?: boolean
        tracesSampleRate?: number
        environment?: string
    }
    hive: {
        host?: string
        emqxMqttWsUrl?: string
    }
    featureFlags: {
        [ECOU_JOURNAL]: boolean | RoleName
        [ECOU_MEASURES]: boolean | RoleName
    }
}

export const Config: ConfigInterface = {
    site: {
        title: 'ENER-IQ',
        footerText: 'ENER-IQ Software Stack - @ 2023 ENER-IQ GmbH',
        version: process.env.REACT_APP_VERSION || undefined
    },
    schemaRouteResolvers: {
        technical_installations: TechnicalInstallationsResolver
    },
    essApi: {
        host: process.env.REACT_APP_ESS_API_HOST || undefined
    },
    hive: {
        host: process.env.REACT_APP_TIMESERIES_API_HOST || undefined,
        emqxMqttWsUrl: process.env.REACT_APP_EMQX_WS_MQTT_ENDPOINT
    },
    sentry: {
        dsn: process.env.REACT_APP_SENTRY_DSN || undefined,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || undefined,
        enabled: Boolean(Number(`${process.env.REACT_APP_SENTRY_ENABLED}`)),
        tracesSampleRate: isEmpty(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
            ? 0
            : Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
    },
    featureFlags: {
        [ECOU_JOURNAL]: 'ROLE_INTERNAL',
        [ECOU_MEASURES]: 'ROLE_INTERNAL'
    }
}

if (process.env.REACT_APP_FEATURE_FLAG_ECOU_JOURNAL !== undefined) {
    if ([1, true, '1', 'true'].includes(process.env.REACT_APP_FEATURE_FLAG_ECOU_JOURNAL)) {
        Config.featureFlags[ECOU_JOURNAL] = true
    }
    else if ([0, false, '0', 'false'].includes(process.env.REACT_APP_FEATURE_FLAG_ECOU_JOURNAL)) {
        Config.featureFlags[ECOU_JOURNAL] = false
    }
    else if (typeof process.env.REACT_APP_FEATURE_FLAG_ECOU_JOURNAL === 'string') {
        Config.featureFlags[ECOU_JOURNAL] = process.env.REACT_APP_FEATURE_FLAG_ECOU_JOURNAL
    }
}

if (process.env.REACT_APP_FEATURE_FLAG_ECOU_MEASURES !== undefined) {
    if ([1, true, '1', 'true'].includes(process.env.REACT_APP_FEATURE_FLAG_ECOU_MEASURES)) {
        Config.featureFlags[ECOU_MEASURES] = true
    }
    else if ([0, false, '0', 'false'].includes(process.env.REACT_APP_FEATURE_FLAG_ECOU_MEASURES)) {
        Config.featureFlags[ECOU_MEASURES] = false
    }
    else if (typeof process.env.REACT_APP_FEATURE_FLAG_ECOU_JOURNAL === 'string') {
        Config.featureFlags[ECOU_MEASURES] = process.env.REACT_APP_FEATURE_FLAG_ECOU_MEASURES
    }
}
