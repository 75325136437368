export const queryItem = <S = unknown>(key: string, notSetValue: unknown = undefined): (S | undefined) => {
    let storageItem

    if (key) {
        const val = localStorage.getItem(key) || null

        try {
            storageItem = val ? JSON.parse(val) : val
        }
        catch (_) {
            storageItem = val
        }

        if (!storageItem) {
            storageItem = notSetValue
        }
    }

    return storageItem
}
