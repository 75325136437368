import { useRef, useEffect } from 'react'

type CleanupCallback = () => void

export const useCleanupCallback = (cleanupCallback: CleanupCallback): void => {
    const callbackRef = useRef(cleanupCallback)

    callbackRef.current = cleanupCallback // always up to date
    useEffect(() => () => callbackRef.current(), [])
}
