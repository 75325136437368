import { AxiosRequestHeaders } from 'axios'

import {
    Api,
    initClient as baseInitClient
} from './client'

export { getClient } from './client/api/http-client/create-client'

interface InitClientOptions {
    jwt?: string | null
}

const initClient = ({ jwt }: InitClientOptions = {}, forceNew = false): void => {
    const headers: AxiosRequestHeaders = {
        Accept: 'application/ld+json'
    }

    if (jwt) {
        headers.authorization = `Bearer ${jwt}`
    }

    baseInitClient({
        host: `${process.env.REACT_APP_ESS_API_HOST}`,
        httpClient: {
            headers
        }
    }, forceNew)
}

initClient()

export {
    Api,
    initClient
}
