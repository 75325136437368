import dayjs, { ManipulateType } from 'dayjs'

import { DateRangeValueType } from '../../components/input/date-range'

import { AcceptedRangeInput, DateRange, SerializedDateRange } from './types'

export const normalizeDateRange = (input: AcceptedRangeInput | DateRange): DateRange => {
    const prepared = { ...input }

    if (prepared.from && typeof prepared.from === 'string') {
        const match = prepared.from.match(/^([-|+])(\d+) (\w+)$/i)

        if (match) {
            prepared.from = match[1] === '+'
                ? dayjs().add(Number(match[2]), match[3] as ManipulateType)
                : dayjs().subtract(Number(match[2]), match[3] as ManipulateType)
        }
    }
    if (prepared.to && typeof prepared.to === 'string') {
        const match = prepared.to.match(/^([-|+])(\d+) (\w+)$/i)

        if (match) {
            prepared.to = match[1] === '+'
                ? dayjs().add(Number(match[2]), match[3] as ManipulateType)
                : dayjs().subtract(Number(match[2]), match[3] as ManipulateType)
        }
    }

    return {
        from: prepared.from === undefined ? undefined : dayjs(prepared.from),
        to: prepared.to === undefined ? undefined : dayjs(prepared.to)
    }
}

export const serializeDateRange = (input: DateRangeValueType | DateRange, template?: string): SerializedDateRange => {
    const res: SerializedDateRange = {}

    if (input.from) {
        res.from = dayjs(input.from).format(template)
    }
    if (input.to) {
        res.to = dayjs(input.to).format(template)
    }

    return res
}

export const shiftDateRange = (range: DateRangeValueType, factor: number): DateRangeValueType => {
    if (range.from && range.to) {
        const diff = dayjs(range.to).diff(range.from)
        const shift = diff * factor

        return {
            from: dayjs(range.from).add(shift, 'ms').format(),
            to: dayjs(range.to).add(shift, 'ms').format()
        }
    }

    return {
        from: dayjs(range.from).format(),
        to: dayjs(range.to).format()
    }
}

export const dateFromZoomInput = (input: unknown): dayjs.Dayjs | undefined => {
    if (typeof input === 'string') {
        const matches = input.match(/(?<count>\d+)(?<unit>[hdwmy]{1})/)

        if (matches) {
            // console.log('in data range prov', matches)
            const count = Number(matches[1])
            let unit: ManipulateType

            switch (matches[2] as string) {
                case 'h':
                    unit = 'hours'
                    break
                case 'd':
                    unit = 'days'
                    break
                case 'w':
                    unit = 'weeks'
                    break
                case 'm':
                    unit = 'months'
                    break
                case 'y':
                    unit = 'years'
                    break
                default:
                    unit = 'days'
                    break
            }

            return dayjs().subtract(count, unit)
        }

        return dayjs(input)
    }

    return undefined
}
