import React, { PropsWithChildren, useMemo } from 'react'
import { AnyAbility } from '@casl/ability'

import { useAuth } from '../../hooks/use-auth'

import { AbilityContext } from './ability-context'
import defineAbilityForAuthToken from './define-ability-for-auth-token'
import defineDefaultAbility from './define-default-ability'

interface Props {
    ability?: AnyAbility
}

export const AbilityProvider: React.FC<PropsWithChildren<Props>> = ({ children, ability }) => {
    const { token, organization, user } = useAuth()
    const processedAbility = useMemo<AnyAbility>(() => {
        if (ability) {
            return ability
        }

        if (token) {
            return defineAbilityForAuthToken(token, user, organization)
        }

        return defineDefaultAbility
    }, [token, organization, user, ability])

    return (
        <AbilityContext.Provider
            value={processedAbility}
        >
            {children}
        </AbilityContext.Provider>
    )
}

export default AbilityProvider
