import axios from 'axios'

import { PersistentJsonLdEntity } from '../../contract'
import { getClient } from '../http-client'
import { normalizePreload } from '../helper/normalize-preload'
import { normalizeFields } from '../helper/normalize-fields'
import { ConstraintViolationList } from '../../schema/constraint-violation-list'
import { ValidationError } from '../validation/validation-error'
import { normalizeApiResourceName } from '../helper/normalize-api-resource-name'

export interface UpdateItemOptions {
    fields?: string[]
    preload?: string[]
}

export const updateItem = async <Model extends object>(
    resourceName: string,
    id: string,
    payload: Partial<Model>,
    options?: UpdateItemOptions
): Promise<Model & PersistentJsonLdEntity> => {
    try {
        const res = await getClient().put<Model & PersistentJsonLdEntity>(`/api/${normalizeApiResourceName(resourceName)}/${id}`, payload, {
            headers: {
                fields: options?.fields ? normalizeFields(options?.fields) : false,
                preload: options?.preload ? normalizePreload(options?.preload) : false
            }
        })

        return res.data
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 422 && error.response?.data) {
                const data = error.response.data as ConstraintViolationList
                const description = 'hydra:description' in data ? data['hydra:description'] : undefined

                throw new ValidationError(`${description}`, data.violations)
            }
        }

        throw error
    }
}
