import { createContext } from 'react'

import { User } from 'components/users/model'
import { Organization } from 'components/organizations/model'
import { AuthToken } from 'components/auth/model'

export interface IAuthContext {
    isAuthenticated: boolean
    jwt: string | null
    token?: AuthToken
    user?: User
    isLoading?: boolean
    organization?: Organization
    signIn: (token: AuthToken) => void
    signOut: () => void
    forceSignOutRedirect: (withNotification?: boolean) => void
    hasRole: (role: string) => boolean
}

export const AuthContext = createContext<IAuthContext>({
    isAuthenticated: false,
    jwt: null,
    signIn: () => undefined,
    signOut: () => undefined,
    hasRole: () => false,
    forceSignOutRedirect: () => undefined
})

export default AuthContext
