import dayjs from 'dayjs'

export interface Collection<S> {
    'data': S[]
}

export interface TimeWindow {
    min?: number
    max?: number
}

enum DataProviderState {
    OK = 'OK',
    OVERDUE = 'OVERDUE',
    OFFLINE = 'OFFLINE',
}

export interface LatestDataProviderValue {
    dataPointId: string
    time: Date | null
    value: number | null
    lastErrorTime: Date | null
    lastError: string | null
    state: DataProviderState
    averageInterval?: number
    nextValueExpectedIn?: number
}

export type ChartableItemData = number | null;
export type ChartablePoint = [number, ChartableItemData];
export type ItemsData = { [key: string]: ChartableItemData };
export type GapFilledItemData = { [key: number]: ChartableItemData };

export interface ChartableResult<S = ItemsData> {
    result: S[]
    timeWindow: TimeWindow
    fetchedAt: dayjs.Dayjs
}

export interface DataPointValue {
    dataPointId: string
    time: number | string
    value: number | null
    lastErrorTime?: string | null
    lastError?: string | null
}

export interface AggregationValue {
    dataPointId: string
    bucket: string
    avgValue: number
    diff: number
    stddevValue: number
    minValue: number
    maxValue: number
    numValues: number
}

export const AggregationKeys = ['avgValue', 'diff', 'stddevValue', 'minValue', 'maxValue', 'totalValues']
export type AggregationKey = 'avgValue' | 'diff' | 'stddevValue' | 'minValue' | 'maxValue' | 'totalValues'

export interface ChartableRawData extends ItemsData {
    time: number
}

export interface ChartableBucketData extends ItemsData {
    bucket: number
}

export interface BucketOrientedChartableResult {
    result: ChartableBucketData[]
}

export type MinutelyResolutions = '1 minute' | '5 minute'
export type HourlyResolutions = '1 hour'
export type TimeseriesResolution = MinutelyResolutions | HourlyResolutions | '1 day' | 'raw'

export const Buckets = ['minutely', '5minutely', 'hourly', 'daily', 'weekly', 'monthly', 'yearly']

export type AvailableBucket = 'minutely' | '5minutely' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'
