import { JsonLdEntity } from '../../contract'
import { getClient } from '../http-client'
import { normalizeApiResourceName } from '../helper/normalize-api-resource-name'
import { produceDefaultHeaders } from '../helper/produce-default-headers'

import { PreloadAware } from './preload-aware'

export type GetItemOptions = PreloadAware

export const getItem = async <Model extends object>(
    resourceName: string,
    id: string,
    options?: GetItemOptions
): Promise<Model & JsonLdEntity> => {
    const res = await getClient().get<Model & JsonLdEntity>(`/api/${normalizeApiResourceName(resourceName)}/${id}`, {
        headers: {
            ...produceDefaultHeaders(options)
        }
    })

    return res.data
}
