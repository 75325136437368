import React, { Suspense } from 'react'
import 'dayjs/locale/de'
import { locale as dayjsLocale } from 'dayjs'
import { IntlProvider as BaseIntlProvider } from 'react-intl'

import { getTranslationMessages } from '../i18n'
import { LazyProgress } from '../components/lazy-progress'

export interface Props {
    children: React.ReactNode
    locale: string
}

const cache: Record<string, Record<string, string>> = {}

const loadAsync = async (locale: string) => {
    const messages = await getTranslationMessages(locale)

    cache[locale] = messages as Record<string, string>

    return messages
}

const getMessages = (locale: string): Record<string, string> => {
    if (cache[locale]) {
        return cache[locale]
    }

    throw loadAsync(locale)
}

const IntlProvider: React.FC<Props> = ({ children, locale }) => {
    dayjsLocale(locale)

    return (
        <Suspense fallback={<LazyProgress />}>
            <BaseIntlProvider
                locale={locale}
                messages={getMessages(locale)}
            >
                {children}
            </BaseIntlProvider>
        </Suspense>
    )
}

export default IntlProvider
