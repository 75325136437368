import { defineMessages } from 'react-intl'

export default defineMessages({
    notAuthenticated: {
        id: 'components.auth.not_authenticated',
        defaultMessage: 'Missing authentication'
    },
    notAuthenticatedDescription: {
        id: 'components.auth.not_authenticated.description',
        defaultMessage: 'You are being redirected to the login screen now'
    },
    loginRefreshed: {
        id: 'components.auth.login_refreshed',
        defaultMessage: 'Login refreshed'
    },
    loginRefreshedDescription: {
        id: 'components.auth.login_refreshed.description',
        defaultMessage: 'Your login token expired and was automatically refreshed in the background.'
    }
})
