import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes
} from 'react-router-dom'

import { Config } from './config'
import './index.css'
import App from './app'
import reportWebVitals from './report-web-vitals'

const container = document.getElementById('root')

if (Config.sentry.dsn && Config.sentry.enabled) {
    Sentry.init({
        release: Config.site.version,
        dsn: Config.sentry.dsn,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            })
        ],
        environment: Config.sentry.environment,
        tracesSampleRate: Config.sentry.tracesSampleRate
    })
}

if (container) {
    const root = createRoot(container)

    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((metric: unknown) => console.debug('[WebVitals]', metric))
