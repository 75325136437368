import { singular } from 'pluralize'
import { pascalize } from 'humps'

import { Iri, Rel } from 'api/ess-api/types'
import { JsonLdEntity } from 'api/ess-api/client'
// import { EssApi } from 'api/ess-api/types'

export const guessMemberIdFromIri = (iri: Iri): string | undefined => {
    // this just extracts the second part after the api-route-prefix ... this will fail if we change the structure
    // thus, @todo replace that with something more sustainable!
    let matches = iri.match(/\/api\/meta\/[\w]+\/([\w-]+)/)

    if (!matches) {
        matches = iri.match(/\/api\/\w+\/([\w-]+)/)
    }

    if (!matches && (iri.length === 36 || iri.length === 12)) {
        return iri
    }

    return matches
        ? matches[1]
        : undefined
}

export const guessResourceNameFromIri = (iri: Iri): string | undefined => {
    // this just extracts the first part after the api-route-prefix ... this will fail if we change the structure
    // thus, @todo replace that with something more sustainable!
    let matches = iri.match(/\/api\/(meta\/[\w]+)/)

    if (!matches) {
        matches = iri.match(/\/api\/(\w+)/)
    }

    // console.log('detail for iri guessResourceNameFromIri', iri, matches);

    return matches
        ? matches[1]
        : undefined
}

export const normalizeResourceTitle = (input: string): string => pascalize(singular(input))

export const guessResourceTitleFromIri = (iri: Iri): string | undefined => {
    const resourceName = guessResourceNameFromIri(iri)

    if (resourceName) {
        return normalizeResourceTitle(resourceName)
    }

    return undefined
}

interface RelationIdentifier {
    resourceName: string
    memberId: string
}

export const getIdentifierFromRelation = (relation: Rel<unknown>): RelationIdentifier | undefined => {
    if (typeof relation === 'string') {
        const resourceName = guessResourceNameFromIri(relation)
        const memberId = guessMemberIdFromIri(relation)

        if (resourceName && memberId) {
            return {
                resourceName,
                memberId
            }
        }
    }
    else if ((relation as JsonLdEntity)['@id']) {
        return getIdentifierFromRelation((relation as JsonLdEntity)['@id'])
    }

    return undefined
}
