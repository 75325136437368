import { createContext } from 'react'

import { SchemaRouteResolver } from '../../helper/route-resolver/schema-route-resolver'

export interface ISchemaRoutesContext {
    addResolver: (resourceName: string, resolver: SchemaRouteResolver) => void
    getItemRoute: (item: unknown, context?: unknown) => Promise<string | undefined>
    getCollectionRoute: (resourceName: string, context?: unknown) => Promise<string | undefined>
}

export const SchemaRoutesContext = createContext<ISchemaRoutesContext>({
    addResolver: () => undefined,
    getItemRoute: () => Promise.resolve(undefined),
    getCollectionRoute: () => Promise.resolve(undefined)
})

export default SchemaRoutesContext
