import { Theme } from '@emotion/react'

import buildingImage from './eneriq-assets/pexels-photo-209292.jpg'
import workImage from './eneriq-assets/work.jpg'

export const Eneriq: Theme = {
    color: {
        sidebarText: 'rgb(120, 129, 149)',
        sidebarBackground: '#2d3446',
        siderSeparator: 'rgb(240 240 240 / 12%)',
        logoBackground: '#1e2331',

        menuItemSelectedBackground: 'rgba(0, 0, 0, 0.3)'
    },
    logo: {
        image: '/themes/eneriq/logo.svg'
    },
    frontPage: {
        defaultImage: workImage,
        signInImage: buildingImage
    }
}
