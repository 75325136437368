import { createContext } from 'react'

import { AcceptedDateInput, AcceptedRangeInput, DateRange, RangeText, ZoomRange } from './types'

export interface IDateRangeContext {
    dateRange: DateRange
    setDateRange: (range: AcceptedRangeInput | DateRange) => void
    setFromDate: (date: AcceptedDateInput) => void
    setToDate: (date: AcceptedDateInput) => void

    zoomRange: ZoomRange
    setZoomRange: (range: AcceptedRangeInput | ZoomRange | RangeText) => void
    setZoomFromDate: (date: AcceptedDateInput) => void
    setZoomToDate: (date: AcceptedDateInput) => void
}

export const DateRangeContext = createContext<IDateRangeContext>({
    dateRange: {},
    setDateRange: () => undefined,
    setFromDate: () => undefined,
    setToDate: () => undefined,

    zoomRange: {},
    setZoomRange: () => undefined,
    setZoomFromDate: () => undefined,
    setZoomToDate: () => undefined
})

export default DateRangeContext
