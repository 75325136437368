import { getClient } from '../http-client'
// import { handlePreloads } from '../helper/handle-preloads'
import { normalizeApiResourceName } from '../helper/normalize-api-resource-name'
import { HydraCollection } from '../../contract'
import { produceDefaultHeaders } from '../helper/produce-default-headers'
import { UnexpectedResponseError } from '../../error/unexpected-response-error'

import { PreloadAware } from './preload-aware'

export interface GetCollectionOptions extends PreloadAware {
    filters?: Record<string, unknown>
    itemsPerPage?: number
    page?: number
}

export const getCollection = async <Model extends object>(
    resourceName: string,
    options?: GetCollectionOptions
): Promise<HydraCollection<Model>> => {
    const res = await getClient().get(`/api/${normalizeApiResourceName(resourceName)}`, {
        params: {
            ...options?.filters,
            itemsPerPage: options?.itemsPerPage,
            page: options?.page || 1
        },
        headers: {
            ...produceDefaultHeaders(options)
        }
    })

    const { data } = res

    if (data['@type'] === 'hydra:Collection') {
        return data
    }

    throw new UnexpectedResponseError(`Did not receive a hydra collection response from "${resourceName}" API`)
}
