import React, { useCallback, useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'
import { notification } from 'antd'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTimeoutFn } from 'react-use'
import { useMutation } from 'react-query'

import { useLocalStorage } from 'hooks/use-local-storage'
import { useGetIri } from '../../components/data/use-get-iri'
import { useGetMember } from '../../components/data/use-get-member'
import { AuthToken } from 'components/auth/model'
import { User } from 'components/users/model'
import { Organization } from 'components/organizations/model'
import { initClient, getClient, Api } from 'api/ess-api'
import { Api as TimeseriesApi } from 'api/timeseries-api'
import messages from 'components/auth/auth.intl'

import AuthContext from './auth-context'

interface IProps {
    children: React.ReactElement
    showRefreshNotification?: boolean
}

export const API_TOKEN_STORAGE_KEY = 'api_token'
export const JWT_STORAGE_KEY = 'jwt'

// time _before_ the token expiry to cause a refresh!
const REFRESH_GRACE_MILLISECONDS = 10000

export const AuthProvider: React.FC<IProps> = ({ children, showRefreshNotification }) => {
    const intl = useIntl()
    const location = useLocation()
    const navigate = useNavigate()
    const interceptorRegisteredForClient = useRef<ReturnType<typeof getClient>>()
    const redirectedToLogin = useRef<boolean>(false)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [previousApiTokenResponse, setPreviousApiTokenResponse] =
        useLocalStorage<AuthToken | undefined>(API_TOKEN_STORAGE_KEY)
    const [apiTokenResponse, setApiTokenResponse] = useState<AuthToken | undefined>(previousApiTokenResponse)
    const [jwt, setJwt] = useLocalStorage<string | null | undefined>(JWT_STORAGE_KEY, previousApiTokenResponse?.token)

    useEffect(() => {
        if (apiTokenResponse?.expiresAt && dayjs(apiTokenResponse?.expiresAt).isAfter(dayjs())) {
            setPreviousApiTokenResponse(apiTokenResponse)
            setJwt(apiTokenResponse.token)

            // when the JWT changed we need to (re-)init the API client
            initClient({ jwt: apiTokenResponse.token }, true)

            // also update the default headers for the timeseries api client
            // eslint-disable-next-line dot-notation
            TimeseriesApi.httpClient.defaults.headers.common['Authorization'] = `Bearer ${apiTokenResponse.token}`

            setIsAuthenticated(true)

            Sentry.setUser({
                id: apiTokenResponse.userIdentifier || undefined
            })
        }
    }, [apiTokenResponse, setPreviousApiTokenResponse, setJwt])

    const handleSignOut = useCallback(() => {
        setIsAuthenticated(false)
        setJwt(undefined)
        setApiTokenResponse(undefined)
        setPreviousApiTokenResponse(undefined)

        Sentry.setUser(null)
    }, [setIsAuthenticated, setJwt, setApiTokenResponse, setPreviousApiTokenResponse])

    const forceSignOutRedirect = useCallback((withNotification = true) => {
        if (!redirectedToLogin.current) {
            if (withNotification) {
                notification.error({
                    message: intl.formatMessage(messages.notAuthenticated),
                    description: intl.formatMessage(messages.notAuthenticatedDescription)
                })
            }

            const redirectUrl = `${location.pathname}${location.search}`

            handleSignOut()
            navigate(`/signin?url=${encodeURIComponent(redirectUrl)}`)
            redirectedToLogin.current = true
        }
    }, [handleSignOut, intl, location, navigate])

    const { data: user, isLoading: userIsLoading } = useGetIri<User>(`${apiTokenResponse?.userIdentifier}`, undefined, {
        enabled: typeof apiTokenResponse?.userIdentifier === 'string',
        onError: () => {
            forceSignOutRedirect()
        }
    })
    const { data: organization, isLoading: orgaIsLoading } = useGetMember<Organization>('organizations', `${apiTokenResponse?.currentOrganizationId}`, undefined, {
        enabled: typeof apiTokenResponse?.currentOrganizationId === 'string'
    })

    const { mutate: performTokenRefresh } = useMutation<AuthToken, unknown, AuthToken>(
        'refresh-token',
        Api.refreshAuthToken,
        {
            onSuccess: (data) => {
                setApiTokenResponse(data)

                if (showRefreshNotification) {
                    notification.info({
                        message: intl.formatMessage(messages.loginRefreshed),
                        description: intl.formatMessage(messages.loginRefreshedDescription)
                    })
                }
            }
        }
    )

    const [refreshTokenTimeout, setRefreshTokenTimeout] = useState<number>()

    const [, cancelRefreshTimer] = useTimeoutFn(() => {
        if (refreshTokenTimeout && apiTokenResponse) {
            performTokenRefresh(apiTokenResponse)
        }
    }, refreshTokenTimeout)

    useEffect(() => {
        if (!apiTokenResponse?.expiresAt) {
            cancelRefreshTimer()
        }
    }, [apiTokenResponse, cancelRefreshTimer])

    useEffect(() => {
        if (apiTokenResponse?.expiresAt) {
            const timeout = dayjs(apiTokenResponse.expiresAt).valueOf() - REFRESH_GRACE_MILLISECONDS - dayjs().valueOf()

            if (timeout >= REFRESH_GRACE_MILLISECONDS) {
                setRefreshTokenTimeout(timeout)
            }
            else {
                setRefreshTokenTimeout(10)
            }
        }
    }, [apiTokenResponse?.expiresAt])

    const hasRole = useCallback((role: string): boolean =>
        Boolean(apiTokenResponse?.roles?.includes(role)), [apiTokenResponse?.roles])

    const currentClient = getClient()

    // redirect user to login page when the first 401 occurs
    if (!interceptorRegisteredForClient.current || (interceptorRegisteredForClient.current !== currentClient)) {
        // eslint-disable-next-line promise/prefer-await-to-callbacks
        currentClient.interceptors.response.use(undefined, (error) => {
            // ensure that this happens only if there was a token already!
            if (error instanceof AxiosError &&
                apiTokenResponse &&
                error.response?.status === 401
            ) {
                forceSignOutRedirect()
            }

            throw error
        })
        interceptorRegisteredForClient.current = currentClient
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                forceSignOutRedirect,
                isLoading: userIsLoading || orgaIsLoading,
                token: apiTokenResponse,
                jwt: jwt || null,
                user,
                organization,
                signIn: (token) => {
                    redirectedToLogin.current = false
                    setApiTokenResponse(token)
                },
                signOut: handleSignOut,
                hasRole
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
