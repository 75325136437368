import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { stringify } from 'qs'
import { parse } from 'papaparse'
import dayjs from 'dayjs'

import { queryItem } from 'helper'

import {
    AggregationValue,
    AvailableBucket,
    ChartableBucketData,
    ChartableRawData,
    ChartableResult,
    DataPointValue,
    ItemsData,
    LatestDataProviderValue,
    TimeseriesResolution
} from './types'

const token = queryItem<string>('jwt')
const DEFAULT_LIMIT = 15000

export const httpClient = axios.create({
    baseURL: process.env.REACT_APP_TIMESERIES_API_HOST
})

// eslint-disable-next-line dot-notation
httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`

export interface CsvOutputOptions {
    replaceIds?: boolean
    dateFormat?: string
    extract?: string
}

export interface TimeseriesQueryParams extends CsvOutputOptions, Record<string, unknown> {
    from?: string
    to?: string
    take?: number
    skip?: number
    order?: 'DESC' | 'ASC'
}

export type ChartableTimeseriesQueryKey = [
    prefix: string,
    dataPointId: string,
    resolution: string,
    queryParams?: TimeseriesQueryParams
]

const resolutionToBucket = (resolution: TimeseriesResolution): AvailableBucket => {
    switch (resolution) {
        case '1 day':
            return 'daily'
        case '1 hour':
            return 'hourly'
        case '5 minute':
            return '5minutely'
        case '1 minute':
        default:
            return 'minutely'
    }
}

export const getChartableTimeseries = async (
    dataPointId: string,
    resolution: TimeseriesResolution,
    query?: TimeseriesQueryParams,
    paginate = true
): Promise<ChartableResult<ChartableBucketData>> => {
    const preparedQueryParams: TimeseriesQueryParams = {
        take: DEFAULT_LIMIT,
        ...query
    }

    if (!dataPointId || isEmpty(dataPointId)) {
        throw new Error('No dataPointId set')
    }

    if (!resolution || isEmpty(resolution)) {
        throw new Error('No resolution set')
    }

    let url = new URL(
        `${process.env.REACT_APP_TIMESERIES_API_HOST}/datapoint/${dataPointId}/values?${stringify(preparedQueryParams)}`
    )

    if (resolution !== 'raw') {
        url = new URL(
            `${process.env.REACT_APP_TIMESERIES_API_HOST}/datapoint/${dataPointId}/bucket/${resolutionToBucket(resolution)}?${stringify(preparedQueryParams)}`
        )
    }

    const res = await fetchAndParseChartableCsv<ChartableBucketData>(url, paginate)

    if (isEmpty(res.result) && preparedQueryParams.from) {
        const from = dayjs(preparedQueryParams.from)
        const to = dayjs(preparedQueryParams.to)

        for (let m = dayjs(from); m.isBefore(to); m = m.add(1, 'hour')) {
            res.result.push({ bucket: m.valueOf(), time: m.valueOf(), [dataPointId]: null })
        }
    }

    return res
}

export const getRawTimeseries = (
    dataPointId: string,
    query?: TimeseriesQueryParams,
    paginate = true
): Promise<ChartableResult<ChartableRawData>> => {
    const preparedQueryParams: TimeseriesQueryParams = {
        take: DEFAULT_LIMIT,
        ...query
    }

    if (!dataPointId || isEmpty(dataPointId)) {
        throw new Error('No dataPointId set')
    }

    const url = new URL(
        `${process.env.REACT_APP_TIMESERIES_API_HOST}/datapoint/${dataPointId}/values?${stringify(preparedQueryParams)}`
    )

    return fetchAndParseChartableCsv<ChartableRawData>(url, paginate)
}

export const getLatestValue = async (
    dataPointId: string
): Promise<DataPointValue> => {
    const url = new URL(
        `${process.env.REACT_APP_TIMESERIES_API_HOST}/datapoint/${dataPointId}/latest`
    )

    const res = await httpClient.get<DataPointValue>(url.toString())

    return res.data
}

export const getLatestDataProviderValue = async (dataProviderId: string): Promise<LatestDataProviderValue> => {
    const url = new URL(
        `${process.env.REACT_APP_TIMESERIES_API_HOST}/devices/${dataProviderId}/latest`
    )

    const res = await httpClient.get<LatestDataProviderValue>(url.toString())

    return res.data
}

export const getAggregationValue = async (
    dataPointId: string,
    bucket: AvailableBucket,
    query?: TimeseriesQueryParams
): Promise<AggregationValue> => {
    const preparedQueryParams: TimeseriesQueryParams = {
        take: 1,
        ...query
    }

    const url = new URL(
        `${process.env.REACT_APP_TIMESERIES_API_HOST}/datapoint/${dataPointId}/bucket/${bucket}?${stringify(preparedQueryParams)}`
    )

    const res = await httpClient.get<AggregationValue[]>(url.toString())

    return res.data[0]
}

export const getAggregationValues = async (
    dataPointId: string,
    bucket: AvailableBucket,
    query?: TimeseriesQueryParams,
    limit = 1
): Promise<AggregationValue[]> => {
    const preparedQueryParams: TimeseriesQueryParams = {
        take: limit,
        ...query
    }

    const url = new URL(
        `${process.env.REACT_APP_TIMESERIES_API_HOST}/datapoint/${dataPointId}/bucket/${bucket}?${stringify(preparedQueryParams)}`
    )

    const res = await httpClient.get<AggregationValue[]>(url.toString())

    return res.data
}

export const fetchAndParseChartableCsv = async <S extends ItemsData>(
    url: URL,
    paginate = true
): Promise<ChartableResult<S>> => {
    if (!url.searchParams.get('take')) {
        url.searchParams.set('take', `${DEFAULT_LIMIT}`)
    }

    if (!url.searchParams.get('order')) {
        url.searchParams.set('order', 'ASC')
    }

    const requestedLimit = Number(url.searchParams.get('take'))
    const res = await httpClient.get(url.toString(), {
        headers: {
            Accept: 'text/csv'
        }
    })

    const { data } = res

    let result: ChartableResult<S> = {
        result: [] as S[],
        timeWindow: {},
        fetchedAt: dayjs()
    }

    if (data) {
        const parsed = parse<S>(data, {
            header: true,
            transform: (value: string, field: string): number | null => {
                if (['bucket', 'time'].includes(`${field}`)) {
                    return dayjs(value).valueOf()
                }

                if (value === '') {
                    return null
                }

                return parseFloat(value)
            }
        })

        if (parsed.errors) {
            // console.warn('Got errors parsing API CSV response', parsed.errors)

            for (const error of parsed.errors) {
                if (error.row) {
                    parsed.data.splice(error.row, 1)
                }
            }
        }

        if (parsed.data) {
            result.result = parsed.data
            result.fetchedAt = dayjs()

            if (get(result, 'result[0].bucket')) {
                result.timeWindow.min = get(result, 'result[0].bucket', undefined)
                result.timeWindow.max = get(result, `result[${result.result.length - 1}].bucket`, undefined)
            }
            else if (get(result, 'result[0].time')) {
                result.timeWindow.min = get(result, 'result[0].time', undefined)
                result.timeWindow.max = get(result, `result[${result.result.length - 1}].time`, undefined)
            }

            if (paginate && parsed.data.length === requestedLimit && result.timeWindow.max) {
                const nextUrl = new URL(url.toString())
                const currentSkip = Number(url.searchParams.get('skip') || '0')
                const nextSkip = currentSkip + requestedLimit

                nextUrl.searchParams.set('skip', `${nextSkip}`)
                const nextResult = await fetchAndParseChartableCsv<S>(new URL(nextUrl))

                if (nextResult && nextResult.result) {
                    result = {
                        result: [
                            ...result.result,
                            ...nextResult.result
                        ],
                        fetchedAt: dayjs(),
                        timeWindow: {
                            min: Math.max(get(result, 'timeWindow.min', 0), get(nextResult, 'timeWindow.min', 0)),
                            max: Math.max(get(result, 'timeWindow.max', 0), get(nextResult, 'timeWindow.max', 0))
                        }
                    }
                }
            }
        }
    }

    return result
}
