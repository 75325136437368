export const enum AppEnvironment {
    LOCAL = 'local',
    TEST = 'test',
    E2E = 'e2e',
    DEV = 'dev',
    STAGE = 'stage',
    PROD = 'prod'
}

export const enum NodeEnvironment {
    DEVELOPMENT = 'development',
    PRODUCTION = 'production',
    TEST = 'test'
}
