import axios, { type AxiosInstance, AxiosRequestConfig } from 'axios'

import authHeader from './axios-middleware/auth-header'
import paramsSerializer from './axios-middleware/params-serializer'

let instance: AxiosInstance | undefined

export const createClient = (endpoint: string, options?: AxiosRequestConfig): AxiosInstance => {
    if (instance) {
        instance = undefined
    }

    instance = axios.create({
        baseURL: endpoint,
        ...options,
        headers: {
            ...options?.headers
        }
    })

    instance.interceptors.request.use(authHeader)
    instance.interceptors.request.use(paramsSerializer)

    return instance
}

export const getClient = (): AxiosInstance => {
    if (!instance) {
        throw new Error('You have to create a client first')
    }

    return instance
}
