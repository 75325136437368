import { getCollection } from './get-collection'

export interface GetCountOptions {
    filters?: Record<string, unknown>
}

export const getCount = async (
    resourceName: string,
    options?: GetCountOptions
): Promise<number> => {
    const data = await getCollection(resourceName, {
        filters: options?.filters,
        itemsPerPage: 0,
        page: 1
    })

    if (data?.['hydra:totalItems']) {
        return data?.['hydra:totalItems']
    }

    return 0
}
