import type { Iri } from './client'

export {
    Schema,
    ValidationError
} from './client'
export type {
    PersistentJsonLdEntity
} from './client'

export { Iri }
export type JsonLdResponse = object
export type Rel<TargetObject> = Iri | TargetObject

export interface PreloadDefinition {
    preload?: string[]
    fields?: string[]
}

export type JsonLdContext = string | ({
    '@vocab': string
    hydra: 'http://www.w3.org/ns/hydra/core#'
} & {
    [key: string]: unknown
})

export interface HydraResponse extends JsonLdResponse {
    '@id'?: Iri
    '@type'?: string
    '@context'?: JsonLdContext
}

export interface Collection<S> extends HydraResponse {
    'hydra:member': (S & Item)[]
    'hydra:totalItems'?: number
}

export interface Item extends HydraResponse {
    id?: string | null
}

export type PrimitiveType = string | boolean | number | null

export interface Remark<RemarkCode extends string> {
    code: RemarkCode
    message: string
    meta: Record<string, PrimitiveType>
}
