import isEmpty from 'lodash/isEmpty'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { JsonLdEntity } from 'api/ess-api/client'
import { addMemberToCache, Api, Iri, Item, PreloadDefinition } from 'api/ess-api'

export type UseGetIriResult<ObjectType> = UseQueryResult<ObjectType & JsonLdEntity>

export const useGetIri = <ObjectType extends Item>(
    iri: Iri | undefined | null,
    preload?: PreloadDefinition,
    queryOptions?: UseQueryOptions<ObjectType>
): UseGetIriResult<ObjectType> => useQuery<ObjectType>(
    [iri, preload],
    () => Api.getIri(`${iri}`, {
        preload: preload?.preload,
        fields: preload?.fields
    }),
    {
        enabled: !isEmpty(iri) && `${iri}` !== 'undefined' && !`${iri}`.includes('/undefined'),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        ...queryOptions,
        onSuccess: (callbackData) => {
            addMemberToCache(callbackData)

            if (queryOptions?.onSuccess) {
                queryOptions?.onSuccess(callbackData)
            }
        }
    }
)
