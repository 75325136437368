import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { JsonLdEntity } from 'api/ess-api/client'
import { addMemberToCache, Api, Item, PreloadDefinition } from '../../api/ess-api'
import { guessMemberIdFromIri } from '../../helper'

export type UseGetMemberResult<ObjectType> = UseQueryResult<ObjectType & JsonLdEntity>

export const useGetMember = <ObjectType extends Item>(
    resourceName: string,
    id: string,
    preload?: PreloadDefinition,
    queryOptions?: UseQueryOptions<ObjectType>
): UseGetMemberResult<ObjectType> => {
    const sanitizedId = guessMemberIdFromIri(id) || id
    // const queryKeyEligiblePreload = _.pick(preload, ['fields'])

    return useQuery<ObjectType & JsonLdEntity>(
        [resourceName, sanitizedId],
        () => Api.getItem(resourceName, sanitizedId, {
            preload: preload?.preload
        }),
        {
            enabled: Boolean(resourceName) && Boolean(sanitizedId),
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...queryOptions,
            onSuccess: (callbackData) => {
                addMemberToCache(callbackData)

                if (queryOptions?.onSuccess) {
                    queryOptions?.onSuccess(callbackData)
                }
            }
        }
    )
}
